import React, {type FC, useCallback, useMemo} from 'react';
import {type IntlShape} from 'react-intl';
import classNames from 'classnames';

import ReactSelect from 'components/ReactSelect/ReactSelect';
import {type OptionType} from 'components/ReactSelect/interface';

import {type StudentOption} from './interface';

interface Props {
  applyStudentFilter: (id: number | null) => void;
  intl: IntlShape;
  selectedId?: number;
  students: Array<{id: number; fullName: string; isActive?: boolean}>;
}

const sortByAlphabet = (
  st1: {id: number; fullName: string; isActive?: boolean},
  st2: {id: number; fullName: string; isActive?: boolean}
) => (st1.fullName > st2.fullName ? 1 : -1);

const StudentFilter: FC<Props> = ({applyStudentFilter, intl, selectedId, students}) => {
  const isValueSelected = useCallback<(o: StudentOption) => boolean>(
    option => (!selectedId ? false : option.value === selectedId),
    [selectedId]
  );

  const activeStudents = useMemo(
    () => students.filter(st => st.isActive).sort(sortByAlphabet),
    [students]
  );
  const inActiveStudents = useMemo(
    () => students.filter(st => !st.isActive).sort(sortByAlphabet),
    [students]
  );
  const sortedStudents = useMemo(
    () => activeStudents.concat(inActiveStudents),
    [activeStudents, inActiveStudents]
  );

  const onChange = useCallback<(o: StudentOption) => void>(
    option => {
      applyStudentFilter(option ? option.value : null);
    },
    [applyStudentFilter]
  );

  const formatOptionLabel = useCallback(
    (studentOption: OptionType<number>) => {
      const {isActive} = students.find(student => student.id === studentOption.value)!;
      return <span className={classNames({inactive: !isActive})}>{studentOption.label}</span>;
    },
    [students]
  );

  return (
    <div className="student-select-container">
      <div className="multiselect-container-title">
        {intl.formatMessage({id: 'Common.Role.Student'})}:
      </div>
      <ReactSelect
        options={sortedStudents.map(({id, fullName}) => ({
          label: fullName,
          value: id
        }))}
        formatOptionLabel={formatOptionLabel}
        placeholder={''}
        isMulti={false}
        onChange={onChange}
        isValueSelected={isValueSelected}
      />
    </div>
  );
};

export default StudentFilter;
