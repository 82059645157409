import {type Action} from 'redux';

import {type AxiosRequestAction} from 'services/axios/interface';
import {type StudentTeacher} from 'components/Homework/interface';
import {homeworkPageSize} from 'components/Homework/static';

import {
  RELOAD_TRIGGERED,
  REQUEST_COURSES,
  REQUEST_HOMEWORK_LIST,
  SET_COURSES,
  SET_HOMEWORK_LIST,
  SET_STUDENT_TEACHERS
} from './actionTypes';
import {type Homework} from './interface';

export const requestHomeworkList = (
  hideCompleted?: boolean,
  statuses?: number[],
  studentId?: number,
  pageNumber?: number
): AxiosRequestAction => {
  return {
    type: REQUEST_HOMEWORK_LIST,
    payload: {
      client: 'v2',
      request: {
        method: 'get',
        url: `/v2/homework`,
        params: {
          hideCompleted,
          statuses,
          studentId,
          pageNumber,
          pageSize: homeworkPageSize,
          expand: 'overview'
        }
      }
    }
  };
};

export const requestCourses = (): AxiosRequestAction => ({
  type: REQUEST_COURSES,
  payload: {
    client: 'v2',
    request: {
      method: 'get',
      url: `/v2/dashboard/course`
    }
  }
});

export interface SetHomeworkListAction extends Action {
  homeworkList: Homework[];
}

export const setHomeworkList = (homeworkList: Homework[]): SetHomeworkListAction => ({
  type: SET_HOMEWORK_LIST,
  homeworkList
});

export interface SetCoursesAction extends Action {
  courses: Array<{id: number; name: string}>;
}

export const setCourses = (courses: Array<{id: number; name: string}>): SetCoursesAction => ({
  type: SET_COURSES,
  courses
});

export interface SetStudentTeachersAction extends Action {
  studentTeachers: StudentTeacher[];
}

export const setStudentTeachers = (
  studentTeachers: StudentTeacher[]
): SetStudentTeachersAction => ({
  type: SET_STUDENT_TEACHERS,
  studentTeachers
});

export const reloadTriggered = () => ({type: RELOAD_TRIGGERED});
