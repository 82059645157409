import React, {type FC} from 'react';
import {Route, Routes} from 'react-router-dom';

import {useInjectReducer} from 'hooks/redux/useInjectReducer';

import {PageNotFound} from '../PageNotFound/components/PageNotFound';
import reducer from './reducer';
import {Homework} from './Homework';
import './HomeworkPage.scss';

const HomeworkRoute: FC = () => {
  const isInjected = useInjectReducer({homework: reducer});
  return isInjected ? (
    <div className="homework-page">
      <Routes>
        <Route index={true} element={<Homework />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  ) : null;
};

export default HomeworkRoute;
