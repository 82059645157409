import React, {type FC, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {requestStudentTeachers} from 'store/studentTeachers/requests';
import {type AppState} from 'store/interface';
import {userFullName} from 'helpers/user';
import {type AxiosResponseAction} from 'services/axios/interface';
import {useAxiosDispatch} from 'hooks/redux/useAxiosDispatch';
import {type HomeworkExtra, type StudentTeacher} from 'components/Homework/interface';
import WampErrorMask from 'components/WampErrorMask';
import Loader from 'components/Loader';

import {requestCourses, setCourses, setStudentTeachers} from './actions';
import HomeworkListContainer from './HomeworkListContainer';

export const Homework: FC = () => {
  const dispatch = useDispatch();
  const axiosDispatch = useAxiosDispatch();

  const isStudent = useSelector<AppState, boolean>(({user}) => user.role === 'student');

  const [isErrorOnExtrasRequest, setIsErrorOnExtrasRequest] = useState<boolean | undefined>(
    undefined
  );
  const homeworkList = useSelector(({homework}: AppState) => homework?.homeworkList || []);
  const courses = useSelector<AppState, Array<{id: number; name: string}> | undefined>(
    ({homework}: AppState) => homework?.courses
  );
  const studentTeachers = useSelector<AppState, StudentTeacher[] | undefined>(
    ({homework}: AppState) => homework?.studentTeachers
  );

  const homeworkExtras = useMemo<HomeworkExtra[] | null>(() => {
    if (!courses || !studentTeachers) {
      return null;
    }
    return homeworkList.map(({id, courseInstance: {courseId, studentTeacherId}}) => {
      const courseName = courses.find(c => c.id === courseId)!.name;

      const studentTeacher = studentTeachers.find(st => st.id === studentTeacherId)!;
      const profile = isStudent
        ? studentTeacher.teacher!.profile!
        : studentTeacher.student!.profile!;
      const avatars = profile.avatars.md;
      const fullName = userFullName(profile);
      return {id, courseName, profile: {avatars, fullName}};
    });
  }, [courses, homeworkList, isStudent, studentTeachers]);

  useEffect(() => {
    if (isErrorOnExtrasRequest === undefined) {
      const p1 = axiosDispatch<AxiosResponseAction<StudentTeacher[]>>(
        requestStudentTeachers(isStudent ? 'teacher' : 'student')
      );
      const p2 =
        axiosDispatch<AxiosResponseAction<Array<{id: number; name: string}>>>(requestCourses());

      Promise.all([p1, p2])
        .then(response => {
          dispatch(setStudentTeachers(response[0].payload.data));
          dispatch(setCourses(response[1].payload.data));
        })
        .catch(() => {
          setIsErrorOnExtrasRequest(true);
        });
    }
  }, [axiosDispatch, dispatch, isStudent, isErrorOnExtrasRequest]);

  if (isErrorOnExtrasRequest) {
    return <WampErrorMask reload={() => setIsErrorOnExtrasRequest(undefined)} />;
  }

  if (!homeworkList || !homeworkExtras) {
    return <Loader />;
  }
  return (
    <HomeworkListContainer homeworkExtras={homeworkExtras} studentTeachers={studentTeachers!} />
  );
};
