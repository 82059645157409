import React, {type FC} from 'react';

import Avatar from 'components/Avatar';

import {type CardBodyDecoratorProps} from './interface';
import './CardBodyDecorator.scss';

const CardBodyDecorator: FC<CardBodyDecoratorProps> = ({
  children,
  courseName,
  profile: {avatars, fullName}
}) => {
  return (
    <>
      <div className="course-name">{courseName}</div>
      {children}
      <div className="course-partner">
        <Avatar size={42} url={avatars} />
        <span className="full-name" title={fullName}>
          {fullName}
        </span>
      </div>
    </>
  );
};

export default CardBodyDecorator;
