import {type Action} from 'redux';

import {type ActionHandlersList} from 'store/reducers';
import {
  REQUEST_CHANGE_HOMEWORK_STATUS_SUCCESS,
  REQUEST_DELETE_HOMEWORK_SUCCESS,
  REQUEST_PUBLISH_DRAFT_SUCCESS,
  REQUEST_REACTIVATE_HOMEWORK_SUCCESS
} from 'components/Homework/actionTypes';

import {type HomeworkRouteState} from './interface';
import {
  type SetCoursesAction,
  type SetHomeworkListAction,
  type SetStudentTeachersAction
} from './actions';
import {
  RELOAD_TRIGGERED,
  SET_COURSES,
  SET_HOMEWORK_LIST,
  SET_STUDENT_TEACHERS
} from './actionTypes';

const homeworkChangeListener = (state: HomeworkRouteState): HomeworkRouteState => ({
  ...state,
  reload: true
});

const ACTION_HANDLERS: ActionHandlersList<HomeworkRouteState, Action> = {
  [SET_COURSES]: (state: HomeworkRouteState, {courses}: SetCoursesAction): HomeworkRouteState => ({
    ...state,
    courses
  }),
  [SET_HOMEWORK_LIST]: (
    state: HomeworkRouteState,
    action: SetHomeworkListAction
  ): HomeworkRouteState => ({
    ...state,
    homeworkList: action.homeworkList
  }),
  [SET_STUDENT_TEACHERS]: (
    state: HomeworkRouteState,
    action: SetStudentTeachersAction
  ): HomeworkRouteState => {
    return {...state, studentTeachers: action.studentTeachers};
  },
  // triggers on homework actions
  [REQUEST_PUBLISH_DRAFT_SUCCESS]: homeworkChangeListener,
  [REQUEST_DELETE_HOMEWORK_SUCCESS]: homeworkChangeListener,
  [REQUEST_REACTIVATE_HOMEWORK_SUCCESS]: homeworkChangeListener,
  [REQUEST_CHANGE_HOMEWORK_STATUS_SUCCESS]: homeworkChangeListener,
  [RELOAD_TRIGGERED]: (state: HomeworkRouteState) => ({...state, reload: false})
};

const defaultHomeworkRouteState: HomeworkRouteState = {
  homeworkList: []
};

const reducer = (state: HomeworkRouteState = defaultHomeworkRouteState, action: Action) => {
  const homework = ACTION_HANDLERS[action.type];
  return homework ? homework(state, action) : state;
};

export default reducer;
