import React, {type FC, useCallback, useEffect, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {FormattedMessage, useIntl} from 'react-intl';

import {push, replace} from 'store/router';
import {type AppState} from 'store/interface';
import {userFullName} from 'helpers/user';
import {type AxiosResponseAction} from 'services/axios/interface';
import {useAxiosDispatch} from 'hooks/redux/useAxiosDispatch';
import {useApiRequest} from 'hooks/rest/useApiRequest';
import WampErrorMask from 'components/WampErrorMask';
import Filter from 'components/Homework/Filter';
import HomeworkList from 'components/Homework/HomeworkList';
import SendHomeworkNotificationModal from 'components/Homework/Modals/SendHomeworkNotificationModal';
import {useSanitizeSearch, validStatuses} from 'components/Homework/utils';
import {homeworkPageSize} from 'components/Homework/static';
import {type HomeworkExtra, type StudentTeacher} from 'components/Homework/interface';
import useFilter from 'components/Homework/useFilter';

import {type Homework} from './interface';
import {reloadTriggered, requestHomeworkList, setHomeworkList} from './actions';
import CardBodyDecorator from './CardBodyDecorator';
import StudentFilter from './StudentFilter';

interface OwnProps {
  studentTeachers: StudentTeacher[];
  homeworkExtras: HomeworkExtra[];
}

interface Props extends OwnProps {}

const homeworkPathMapper = ({id, courseInstanceId, courseInstance: {studentTeacherId}}: Homework) =>
  `/room/${studentTeacherId}/course/${courseInstanceId}/homework/${id}`;

const HomeworkListContainer: FC<Props> = ({homeworkExtras, studentTeachers}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const axiosDispatch = useAxiosDispatch();

  const {search: routerSearch} = useLocation();

  const shouldReload = useSelector<AppState, boolean>(({homework}: AppState) => !!homework?.reload);
  const isStudent = useSelector<AppState, boolean>(({user}) => user.role === 'student');
  const homeworkList = useSelector(({homework}: AppState) => homework?.homeworkList || []);
  const userTimeZone = useSelector(({user}: AppState) => user.profile?.timeZone);
  const [homeworkId, setHomeworkId] = useState<string | undefined>(undefined);
  const [courseInstanceId, setCourseInstanceId] = useState<number | undefined>(undefined);
  const onChangeHomeworkStatus = useCallback((homeworkId: string, courseInstanceId: number) => {
    setCourseInstanceId(courseInstanceId);
    setHomeworkId(homeworkId);
  }, []);
  const onHide = useCallback(() => {
    setHomeworkId(undefined);
    setCourseInstanceId(undefined);
  }, []);

  const [hasMore, setHasMore] = useState(false);
  const [shouldResetLoader, setShouldResetLoader] = useState(false);
  const [isErrorOnPageRequest, setIsErrorOnPageRequest] = useState(false);

  const knownParams = useMemo(
    () => ({
      hideCompleted: ['true', 'false'],
      statuses: validStatuses,
      studentId: isStudent
        ? []
        : studentTeachers
          ? studentTeachers!.map(st => String(st.student!.id))
          : []
    }),
    [isStudent, studentTeachers]
  );

  const [requestOptions, search, shouldRefresh] = useSanitizeSearch(routerSearch, knownParams);

  const students = useMemo(() => {
    if (isStudent || !studentTeachers) {
      return undefined;
    }
    return studentTeachers.map(st => ({
      id: st.student!.id!,
      fullName: userFullName(st.student!.profile!),
      isActive: !st.deleted_at
    }));
  }, [isStudent, studentTeachers]);

  const action = useMemo(
    () =>
      requestHomeworkList(
        requestOptions.hideCompleted,
        requestOptions.statuses,
        requestOptions.studentId
      ),
    [requestOptions.hideCompleted, requestOptions.statuses, requestOptions.studentId]
  );
  const reqSuccessHandler = useCallback(
    (hl: Homework[]) => {
      if (hasMore !== (hl.length === homeworkPageSize)) {
        setHasMore(!hasMore);
      }
      dispatch(setHomeworkList(hl));
    },
    [hasMore, dispatch]
  );
  const {isError, isLoading, reload} = useApiRequest(action, reqSuccessHandler);

  const searchChangedCallback = useCallback(() => {
    setHasMore(false);
    setShouldResetLoader(true);
  }, []);

  const {applyHideCompletedFilter, applyStatusesFilter} = useFilter(
    search,
    requestOptions,
    searchChangedCallback
  );

  const applyStudentFilter = useCallback<(studentId: number) => void>(
    studentId => {
      const searchParams = new URLSearchParams(search);
      searchParams.set('hideCompleted', requestOptions.hideCompleted ? 'true' : 'false');
      studentId
        ? searchParams.set('studentId', String(studentId))
        : searchParams.delete('studentId');
      const searchParamsString = searchParams.toString();
      dispatch(push({search: searchParamsString}));
      setHasMore(false);
      setShouldResetLoader(true);
    },
    [dispatch, search, requestOptions.hideCompleted]
  );

  const loadMore = useMemo<
    (page: number) => Promise<AxiosResponseAction<Homework[]> | void>
  >(() => {
    return page => {
      const promise = axiosDispatch<AxiosResponseAction<Homework[]>>(
        requestHomeworkList(
          requestOptions.hideCompleted,
          requestOptions.statuses,
          requestOptions.studentId,
          page
        )
      );
      promise
        .then(({payload: {data}}) => {
          if (data.length < homeworkPageSize) {
            setHasMore(false);
          }

          dispatch(setHomeworkList([...homeworkList, ...data]));
        })
        .catch(() => setIsErrorOnPageRequest(true));
      return promise;
    };
  }, [
    axiosDispatch,
    dispatch,
    homeworkList,
    requestOptions.hideCompleted,
    requestOptions.statuses,
    requestOptions.studentId
  ]);

  useEffect(() => {
    if (shouldRefresh) dispatch(replace({search}));
  }, [dispatch, search, shouldRefresh]);

  useEffect(() => {
    if (shouldReload) {
      setShouldResetLoader(true);
      setHasMore(false);
      dispatch(reloadTriggered());
      reload();
    }
  }, [dispatch, reload, shouldReload]);

  if (isError || isErrorOnPageRequest) {
    return (
      <WampErrorMask
        reload={() => {
          reload();
          setIsErrorOnPageRequest(false);
        }}
      />
    );
  }

  return (
    <>
      <HomeworkList
        CardBodyDecorator={CardBodyDecorator}
        className="user"
        userTimeZone={userTimeZone}
        expanded={true}
        hasMore={hasMore}
        homeworkExtras={homeworkExtras}
        isLoading={isLoading}
        homeworkList={homeworkList}
        homeworkPathMapper={homeworkPathMapper}
        isStudent={isStudent}
        loadMore={loadMore}
        shouldResetLoader={shouldResetLoader}
        setShouldResetLoader={setShouldResetLoader}
        onChangeHomeworkStatus={onChangeHomeworkStatus}
        renderFilter={() => (
          <Filter
            applyHideCompletedFilter={applyHideCompletedFilter}
            applyStatusesFilter={applyStatusesFilter}
            intl={intl}
            requestOptions={requestOptions}
          >
            {!isStudent && students ? (
              <StudentFilter
                applyStudentFilter={applyStudentFilter}
                intl={intl}
                students={students}
                selectedId={requestOptions.studentId}
              />
            ) : null}
          </Filter>
        )}
        renderHeader={() => (
          <h3 className="page-title">
            <FormattedMessage id="LessonPage.Homework" />
          </h3>
        )}
      />
      <SendHomeworkNotificationModal
        courseInstanceId={courseInstanceId}
        homeworkId={homeworkId}
        onHide={onHide}
        studentTeacherId={homeworkList
          .find(h => h.id === homeworkId)
          ?.courseInstance.studentTeacherId.toString()}
      />
    </>
  );
};

export default HomeworkListContainer;
